var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "permission",
              rawName: "v-permission",
              value: "plans.create",
              expression: "'plans.create'",
            },
          ],
          staticClass: "flex flex-wrap-reverse items-center",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary",
              on: { click: _vm.addNew },
            },
            [
              _c("feather-icon", {
                attrs: { icon: "PlusIcon", svgClasses: "h-4 w-4" },
              }),
              _c("span", { staticClass: "ml-2 text-base text-primary" }, [
                _vm._v(_vm._s(_vm.$t("AddNew"))),
              ]),
            ],
            1
          ),
        ]
      ),
      _c("Grid", {
        attrs: {
          service: _vm.service,
          route_name: "plans",
          order_column: "name",
        },
        scopedSlots: _vm._u([
          {
            key: "gridThead",
            fn: function () {
              return [
                _c("vs-th", { key: "id", attrs: { "sort-key": "id" } }, [
                  _vm._v("ID"),
                ]),
                _c("vs-th", { key: "name", attrs: { "sort-key": "name" } }, [
                  _vm._v(_vm._s(_vm.$t("plan.name"))),
                ]),
                _c(
                  "vs-th",
                  {
                    key: "internal_name",
                    attrs: { "sort-key": "internal_name" },
                  },
                  [_vm._v(_vm._s(_vm.$t("plan.internal_name")))]
                ),
                _c(
                  "vs-th",
                  {
                    key: "billing_cycle",
                    attrs: { "sort-key": "billing_cycle" },
                  },
                  [_vm._v(_vm._s(_vm.$t("billing.cycle_")))]
                ),
                _c(
                  "vs-th",
                  { key: "price_value", attrs: { "sort-key": "price_value" } },
                  [_vm._v(_vm._s(_vm.$t("plan.price_value")))]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "gridData",
            fn: function (props) {
              return _vm._l(props.data, function (tr, indextr) {
                return _c(
                  "vs-tr",
                  { key: indextr, attrs: { data: tr } },
                  [
                    _c("vs-td", { key: "id", attrs: { width: "5%" } }, [
                      _c("div", [_vm._v(" " + _vm._s(tr.id) + " ")]),
                    ]),
                    _c("vs-td", { key: "name", attrs: { width: "20%" } }, [
                      _c("div", [_vm._v(" " + _vm._s(tr.name))]),
                    ]),
                    _c(
                      "vs-td",
                      { key: "internal_name", attrs: { width: "20%" } },
                      [_c("div", [_vm._v(" " + _vm._s(tr.internal_name))])]
                    ),
                    _c(
                      "vs-td",
                      { key: "billing_cycle", attrs: { width: "10%" } },
                      [
                        _c("div", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("billing.cycle." + tr.billing_cycle)
                              )
                          ),
                        ]),
                      ]
                    ),
                    _c("vs-td", { key: "value", attrs: { width: "15%" } }, [
                      _c("span", [
                        _vm._v(
                          " " + _vm._s(_vm.formatValue(_vm._.get(tr, "value")))
                        ),
                      ]),
                    ]),
                    _c("vs-td", { attrs: { width: "15%" } }, [
                      _c(
                        "span",
                        { staticClass: "flex" },
                        [
                          _c("feather-icon", {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: "plans.edit",
                                expression: "'plans.edit'",
                              },
                            ],
                            attrs: {
                              icon: "EditIcon",
                              svgClasses:
                                "h-5 w-5 mb-1 mr-3 stroke-current text-warning",
                            },
                            on: {
                              click: function ($event) {
                                return props.edit(props.data[indextr].id)
                              },
                            },
                          }),
                          _c("feather-icon", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip.top",
                                value: {
                                  content: _vm.$t("action.clone"),
                                  delay: { show: 100, hide: 100 },
                                },
                                expression:
                                  "{\n              content: $t('action.clone'),\n              delay: { show: 100, hide: 100 }\n            }",
                                modifiers: { top: true },
                              },
                            ],
                            attrs: {
                              icon: "CopyIcon",
                              svgClasses:
                                "h-5 w-5 mb-1 mr-3 stroke-current text-primary",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.confirmClone(props.data[indextr])
                              },
                            },
                          }),
                          _vm.disableDeletePlan(
                            props.data[indextr].internal_name
                          )
                            ? _c("feather-icon", {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: "plans.delete",
                                    expression: "'plans.delete'",
                                  },
                                ],
                                attrs: {
                                  icon: "Trash2Icon",
                                  svgClasses:
                                    "h-5 w-5 mb-1 mr-3 stroke-current text-danger",
                                },
                                on: {
                                  click: function ($event) {
                                    return props.destroy(props.data[indextr])
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                )
              })
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }