<template>
  <div>
    <div
      v-permission="'plans.create'"
      class="flex flex-wrap-reverse items-center">
      <div
        class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary"
        @click="addNew">
        <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
        <span class="ml-2 text-base text-primary">{{ $t('AddNew') }}</span>
      </div>
    </div>
    <Grid
      :service="service"
      route_name="plans"
      order_column="name">
      <template v-slot:gridThead>
        <vs-th key="id" sort-key="id">ID</vs-th>
        <vs-th key="name" sort-key="name">{{ $t('plan.name') }}</vs-th>
        <vs-th key="internal_name" sort-key="internal_name">{{ $t('plan.internal_name') }}</vs-th>
        <vs-th key="billing_cycle" sort-key="billing_cycle">{{ $t('billing.cycle_') }}</vs-th>
        <vs-th key="price_value" sort-key="price_value">{{ $t('plan.price_value') }}</vs-th>
      </template>
      <template v-slot:gridData="props">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in props.data">
          <vs-td width="5%" key="id">
            <div> {{ tr.id }} </div>
          </vs-td>
          <vs-td width="20%" key="name">
            <div> {{ tr.name }}</div>
          </vs-td>
          <vs-td width="20%" key="internal_name">
            <div> {{ tr.internal_name }}</div>
          </vs-td>
          <vs-td width="10%" key="billing_cycle">
            <div> {{ $t(`billing.cycle.${tr.billing_cycle}`) }}</div>
          </vs-td>
          <vs-td width="15%" key="value">
            <span> {{formatValue(_.get(tr, 'value')) }}</span>
          </vs-td>
          <vs-td width="15%">
            <span class="flex">
            <feather-icon
              v-permission="'plans.edit'"
              icon="EditIcon"
              svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-warning"
              @click="props.edit(props.data[indextr].id)"/>
            <feather-icon
              icon="CopyIcon"
              svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-primary"
              v-tooltip.top="{
                content: $t('action.clone'),
                delay: { show: 100, hide: 100 }
              }"
              @click="confirmClone(props.data[indextr])"/>
            <feather-icon
              v-permission="'plans.delete'"
              v-if="disableDeletePlan(props.data[indextr].internal_name)"
              icon="Trash2Icon"
              svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-danger"
              @click="props.destroy(props.data[indextr])"/>
            </span>
          </vs-td>
        </vs-tr>
      </template>
    </Grid>
  </div>
</template>

<script>
import { currencyFormatter } from '@/util/Util'

import PlansService from '@/services/api/PlansService'

export default {
  directives: {

  },
  components: {

  },
  data: () => ({
    selected: [],
    service: null
  }),
  computed: {

  },
  methods: {
    addNew() {
      this.$router.push('/plans/create')
    },
    confirmClone(data) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: this.$t('confirmacao'),
        acceptText: this.$t('sim'),
        cancelText: this.$t('nao'),
        text: this.$t('tem-certeza-que-deseja-clonar-este-plano'),
        accept: () => {this.clonePlan(data.id)}
      })
    },
    clonePlan(id){
      this.service.clonePlan(id).then(
          data => {
            this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
            this.planEdit(data.id)
          },
          error => {
            this.showErrors(this.$validator, error)
            this.notifyError(this.$vs, this.$t('nao-foi-possivel-realizar-esta-operacao'))
          }
        ).finally(
          () => { this.$vs.loading.close() }
        )
    },
    planEdit(id){
      this.$router.push(`/plans/${id}/edit`)
    },
    disableDeletePlan(name) {
      return !(name === 'STANDARD_PLAN' || name === 'STANDARD_PLAN_INSTITUTION')
    },
    formatValue(value){
      return currencyFormatter(value)
    }
  },
  beforeMount() {
    this.service = PlansService.build(this.$vs)
  }
}
</script>
